/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

//react
import { findByLabelText } from '@testing-library/react'
import React, { useState, useEffect, useRef } from 'react'
import { Route, Routes } from 'react-router-dom'
import NavBar from './components/elements/NavBar'
import SidePanel from './components/elements/SidePanel'
import About from './components/sections/About'
import Footer from './components/sections/Footer'
import Hero from './components/sections/Hero'
import Projects from './components/sections/Projects'
import Work from './components/sections/Work'
import { getDownloadURL, ref } from 'firebase/storage';
import app from './firebase';
import { storage } from './firebase';
import styled from 'styled-components'


const appCss = {
  contentWrapper: css({
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      boxSizing: 'border-box',
      backgroundColor: '#DADADA'
  }),

  sectionWrapper: css({
    paddingLeft: '200px',
    paddingRight: '200px'
  })
}

const Wrapper = styled.div` 
    padding-left: 200px;
    padding-right: 200px;

    @media (max-width: 768px) {
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

    }

    
`


const App = () => {
    return (
        <div css={appCss.contentWrapper}>
            <NavBar/>
            <SidePanel type={'Social'}/>
            <SidePanel type={'Email'}/>
            <Wrapper>
                <Hero />
                <About />
                <Work />
                <Projects />
                <Footer />
            </Wrapper>
        </div>
    )
}



export default App
