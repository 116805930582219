import { initializeApp } from "firebase/app";
import { getFirestore} from 'firebase/firestore/lite'
import { getBlob, getDownloadURL, getStorage, ref } from "firebase/storage";
import { getAuth } from 'firebase/auth'
/* import { getAnalytics } from "firebase/analytics"; */

const firebaseConfig = {
  apiKey: "AIzaSyAJHlW2mHVylUxDI_bQed-JLjzBdVOCRp8",
  authDomain: "portfolio-dbb6c.firebaseapp.com",
  projectId: "portfolio-dbb6c",
  storageBucket: "portfolio-dbb6c.appspot.com",
  messagingSenderId: "101612600399",
  appId: "1:101612600399:web:9c40d1a4f8c9131b9c3e3a",
  measurementId: "G-YGHL0YX3KB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage()
/* const analytics = getAnalytics(app); */

export const auth = getAuth()
export const db = getFirestore(app)

export default app