/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'

const footerCss = {
    footer: css({
      maxWidth: '600px',
      margin: '0px auto 100px',
      textAlign: 'center',
      padding: '100px 0px'
    }),

    title: css({
        display: 'block',
        marginBottom: '20px', 
        color: 'var(--secondary-blue)', 
        fontSize: 'var(--fz-md)', 
        fontWeight: '400'
    }),

    header: css({
        fontSize: 'clamp(40px, 5vw, 60px)',
        margin: '0px 0px 10px', 
        fontWeight: '600', 
        lineHeight: '1.1',
        color: 'var(--secondary-blue)'
    }),

    para: css({
        marginBottom: '5em',
    }),

    contactButton: css({
        border: '1.5px solid var(--secondary-blue)',
        padding: '1.25rem 1.75rem',
        borderRadius: '5px',
        color: 'white',
        backgroundColor: 'var(--secondary-blue)',
        fontSize: 'var(--fz-sm)',
        lineHeight: '1',
        textDecoration: 'none',
        cursor: 'pointer',
        transition: 'var(--transition)',
        marginTop: '50px',
        ':hover': {
           
        }
    })
}

const Footer = () => {
  return (
    <div css={footerCss.footer} id='contact'>
        <h2 css={footerCss.header}>Get In Touch</h2>
        <p css={footerCss.para}>Let's talk! Get in touch and I'll get back to you promptly.</p>
        <a css={footerCss.contactButton} href={`mailto:lewis.v.potter@protonmail.com`} rel="noopener noreferrer" target="_blank">Say Hello</a>
    </div>
  )
}

export default Footer