import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { useEffect } from 'react'

/* import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics"; */

const root = ReactDOM.createRoot(document.getElementById('root'))

/* const firebaseConfig = {
  apiKey: "AIzaSyAJHlW2mHVylUxDI_bQed-JLjzBdVOCRp8",
  authDomain: "portfolio-dbb6c.firebaseapp.com",
  projectId: "portfolio-dbb6c",
  storageBucket: "portfolio-dbb6c.appspot.com",
  messagingSenderId: "101612600399",
  appId: "1:101612600399:web:9c40d1a4f8c9131b9c3e3a",
  measurementId: "G-YGHL0YX3KB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
 */

root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
)