/** @jsxImportSource @emotion/react */
import React from 'react'
import { GitHub } from 'react-feather'
import { Linkedin } from 'react-feather'

import { css } from '@emotion/react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

/* const sidebarCss = {
    leftSide: css({
        width: '40px',
        position: 'fixed',
        bottom: 0,
        left: '40px',
        right: 'auto',
    }),

    rightSide: css({
        width: '40px',
        position: 'fixed',
        bottom: 0,
        left: 'auto',
        right: '40px',
    })
} */

const getSideBarCss = (props) => ({ 
    side: css({
        display:'flex',
        flexDirection: 'column-reverse',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap:'2em',

        width: '200px',
/*         border:'1px solid red', */
        height:'100%',
        position: 'fixed',
        bottom: 0,
        zIndex: 10,
        left: props.type === 'Social' ? 0 : 'auto',
        right: props.type === 'Social' ? 'auto' : 0,
    }),

    line: css({
        border:'1px solid var(--primary-blue)',
        height:'10%'
    }),

    email: css({
        textDecoration: 'none',
        transition: 'var(--transition)',
        writingMode: 'vertical-lr',
        color: 'var(--primary-blue)',
        ':focus': {
            transform: 'translateY(-3px)',
            color: 'var(--secondary-blue)'
        },
        ':hover': {
            transform: 'translateY(-3px)',
            color: 'var(--secondary-blue)'
        }
    }),

    clickableLogo: css({
        transition: 'var(--transition)',
        ':focus': {
            transform: 'translateY(-3px)',
            color: 'var(--secondary-blue)'
        },
        ':hover': {
            transform: 'translateY(-3px)',
            color: 'var(--secondary-blue)'
        }
    })
})

const SidePanel = (props) => {

    switch (props.type){
        case 'Social':
            return (
                <Side type={props.type}>
                    <div css={getSideBarCss(props).line}></div>
                    <a css={getSideBarCss(props).clickableLogo} href={'https://www.linkedin.com/in/lewis-potter-25b746183/'} aria-label={'linkedin-profile'} target="_blank" rel="noreferrer">
                        <Linkedin color='#203f5e'/>
                    </a>

                    <a css={getSideBarCss(props).clickableLogo} href={'https://github.com/lew-vp'} aria-label={'github-profile'} target="_blank" rel="noreferrer">
                        <GitHub color='#203f5e'/>
                    </a>
                </Side>
              )
        case 'Email':
            return (
                <Side type={props.type}>
                    <div css={getSideBarCss(props).line}></div>
                    <a css={getSideBarCss(props).email} href={`mailto:lewis.v.potter@protonmail.com`}>lewis.v.potter@protonmail.com</a>
                </Side>
              )
        default:
            return <></> 
    }
}

const Side = styled.div` 
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: center;
        gap: 2em;

        width: 200px;

        height: 100%;
        position: fixed;
        bottom: 0;
        z-index: 10;
        left: ${props => (props.type === 'Social' ? 0 : 'auto')};
        right: ${props => (props.type === 'Social' ? 'auto' : 0)};

        @media (max-width: 768px) {
            display: none;
        }
`

export default SidePanel