/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import { ChevronRight, GitHub, Link2 } from 'react-feather'
import styled from 'styled-components'


import climbingPhoto from '../../images/climbing_photo.jpg'
import snip from '../../images/snip.JPG'
import pp from '../../images/pp.png'
import { Link } from 'react-router-dom'

const aboutCss = {
    about: css({
      minHeight:'100vh',
      width: '100%',

      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center'
    }),

    container: css({
      maxWidth: '1000px',
	  display: 'flex', 
	  flexDirection: 'column',
	  gap: '1em',
    }),

    header: css({
      display: 'flex',
      justifyContent:'flex-start',
      alignItems: 'center',
      width: '100%',
      fontSize: 'clamp(26px, 5vw, var(--fz-heading))',
      fontWeight: '500',
      color: 'var(--secondary-blue)',
    }),

    line: css({
      width: '345px',
      height: '1px',
      marginLeft: '20px',
      backgroundColor: 'var(--secondary-blue)'
    }),

    inner: css({
      display: 'grid',
      gridTemplateColumns: '2fr 2fr',
	  gap: '1em',
    
    }),

    picture: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      color: 'var(--secondary-blue)'
    }),

    skillsList: css({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(140px, 200px))',
      gap: '10px 10px',
      padding: '0px',
      margin: '20px 0px 0px',
      overflow: 'hidden',
      listStyle: 'none'
    }),

    item: css({
      display: 'flex',
      justifyContent:'flex-start',
      alignItems:'center',
      cursor: 'initial'
    })
}

const Container = styled.div`
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	gap: 1em;

	@media (max-width: 768px) {
		width: 100%;
		padding-left: 2em;
		padding-right: 2em;
		border: 0px solid red;
	}
`

const ProjectPanel = styled.div`
  	display: grid;
    grid-template-columns: 2fr 2fr;
	gap: 1em;

	@media (max-width: 768px) {
		background-image:${snip};
		grid-template-columns: 1fr;
	}
`

const Projects = () => {
  return (
    <div css={aboutCss.about} id='projects'>
		<Container>
			<div css={aboutCss.header}>Projects<div css={aboutCss.line}></div></div>
			<ProjectPanel>
				<InfoSide>
				<h3>Featured Project</h3>
				<h2>Kage Review Site</h2>
				<div>A web app for keeping track of your favourite anime, and leaving reviews for fellow users to read. View the current rankings, learn more about your favourite show, and discover new ones! </div>
				<ul>
					<li>React</li>
					<li>Firebase</li>
					<li>Styled Components</li>
				</ul>
				<ul>
					<li>
						<a href='https://kage-fe4e0.web.app/' target="_blank" rel="noopener noreferrer">
						<Link2 color='grey'/>
						</a>
					</li>
				</ul>
				</InfoSide>
				<div css={aboutCss.picture}><img src={snip} style={{maxWidth: '100%', height: '300px', borderRadius: '9px', border:'1px solid var(--primary-blue)'}} alt="climbing"/></div>
			</ProjectPanel>
		</Container>
    </div>
  )
}

const InfoSide = styled.div`

	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 0;

	h3 {
		margin: 0;
		font-size: 1.1em;
		color: grey;
		font-weight: 500;
		margin-bottom: 0.3em;
	}

	h2 {
		margin: 0;
		color: var(--primary-blue);
		margin-bottom: 0.5em;
	}

	div {
		padding: 1.2em;
		border-radius: 7px;

		margin-bottom: 0.5em;
		background-color: #CDCDCD;
		color: #292929;
	}

	ul {
		width: 100%;
		padding: 0;
		margin: 0;
		margin-bottom: 0.7em;

		display: flex;
		align-items: center;

		gap: 1.5em;
		list-style: none;
		color: var(--primary-blue);
		font-weight: 600;
	}
`

export default Projects