/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import { ChevronRight } from 'react-feather'

import climbingPhoto from '../../images/climbing_photo.jpg'
import pp from '../../images/pp.png'
import styled from 'styled-components'

const aboutCss = {
    about: css({
      minHeight:'100vh',
      width: '100%',

      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center'
    }),

    container: css({
      maxWidth: '900px'
    }),

    header: css({
      display: 'flex',
      justifyContent:'flex-start',
      alignItems: 'center',
      width: '100%',
      fontSize: 'clamp(26px, 5vw, var(--fz-heading))',
      fontWeight: '500',
      color: 'var(--secondary-blue)',
    }),

    line: css({
      width: '365px',
      height: '1px',
      marginLeft: '20px',
      backgroundColor: 'var(--secondary-blue)'
    }),

    inner: css({
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      columnGap: '25px'
    }),

    picture: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      color: 'var(--secondary-blue)'
    }),

    skillsList: css({
      display: 'grid',
      gridTemplateColumns: 'repeat(2, minmax(140px, 200px))',
      gap: '10px 10px',
      padding: '0px',
      margin: '20px 0px 0px',
      overflow: 'hidden',
      listStyle: 'none'
    }),

    item: css({
      display: 'flex',
      justifyContent:'flex-start',
      alignItems:'center',
      cursor: 'initial'
    }),

    link: css({
      textDecoration: 'none',
      color: 'var(primary-blue)'
    })
}

const ProfilePanel = styled.div`

	display: grid;
	grid-template-columns: 3fr 2fr;
	column-gap: 25px;

	@media (max-width: 768px) {
		grid-template-columns: 1fr;
	}

`

const Wrapper = styled.div`

	min-height: 100vh;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
		
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
    width: 100%;
    border: 0px solid red;
    padding-left: 1.6em;
    padding-right: 1.6em;
	}
`

const Header = styled.div`
  display: flex;
  justify-content:flex-start;
  align-items: center;
  width: 100%;
  font-size: clamp(26px, 5vw, var(--fz-heading));
  font-weight: 500;
  color: var(--secondary-blue);

  @media (max-width: 768px) {
  }

  span {
    width: 365px;
    height: 1px;
    margin-left: 20px;
    background-color: var(--secondary-blue);

    @media (max-width: 768px) {
      width: 195px;
    }
  }

`

const About = () => {
  return (
    <Wrapper id='about'>
      <div css={aboutCss.container}>
        <Header>About Me<span/></Header>
        <ProfilePanel>
          <div>
            <p>Hi, my name is Lewis, it's nice to meet you. I am a full-stack web
              and mobile developer with a passion for seamless digital experiences. My love for development
              started in my early school years, with my friends and I building websites for 
              our school band.
            </p>
            <p>After following it through to university, and graduating with a First in Cyber Security, I turned my 
               attention to building web and mobile applications. I currently focus on building gate control and perimeter 
               detection systems for <Link href='https://www.detection-technologies.com/'>Detection Technologies</Link>.
            </p>
            <p>I am very focused on creating intuitive experiences through excellent interface design, 
               and use the knowledge gained through my degree to pair this with robust back-ends. In my downtime,
               I enjoy keeping in shape through all manner of physical activities, a recent favourite being rock climbing
               in the Peak District.
            </p>
            <p>Here are a few technologies I’ve been working with recently:
            </p>
          </div>
          <div css={aboutCss.picture}><img src={pp} style={{maxWidth: '100%', borderRadius: '12px', border:'2px solid var(--primary-blue)'}} alt="climbing"/></div>
          <ul css={aboutCss.skillsList}>
            <li css={aboutCss.item}><ChevronRight color='#203f5e'/>TypeScript</li>
            <li css={aboutCss.item}><ChevronRight color='#203f5e'/>React / React Native</li>
            <li css={aboutCss.item}><ChevronRight color='#203f5e'/>Node.js</li>
            <li css={aboutCss.item}><ChevronRight color='#203f5e'/>Css</li>
            <li css={aboutCss.item}><ChevronRight color='#203f5e'/>Express.js</li>
            <li css={aboutCss.item}><ChevronRight color='#203f5e'/>Azure</li>
          </ul>
        </ProfilePanel>
      </div>
    </Wrapper>
  )
}

const AboutPanel = styled.div`
  min-height: 100vh;
  width:  100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
`

const Link = styled.a`
  text-decoration: none;
  color: var(--secondary-blue);

  &:visited {
    text-decoration: none;
    color: var(--secondary-blue);
  };

`

export default About