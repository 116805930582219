/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import Animation from '../elements/Animation'
import { ChevronDown } from 'react-feather'
import styled from 'styled-components'

const heroCss = {
    hero: css({
        minHeight:'100vh',
        width: '100%',

        display: 'grid',
        gridTemplateColumns: '2fr 1fr'
        /* display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'left'
 */
    }),

    h1: css({
        margin: '0px 0px 25px 4px',
        color: 'var(--primary-blue)',
        fontSize: 'clamp(var(--fz-sm), 5vw, var(--fz-md))',
        fontWeight: '400',
        lineHeight: '1.1'
    }),

    h2: css({
        margin: '0',
        fontWeight: '600',
        lineHeight: '1.1',
        fontSize: 'clamp(40px, 8vw, 80px)',
        color: 'var(--secondary-grey)'
    }),

    h3: css({
        marginTop: '10px',
        margin: '0',
        color: 'var(--secondary-blue)',
        lineHeight: '0.9',
        fontSize: 'clamp(40px, 8vw, 80px)'
    }),

    p: css({
        margin: '20px 0px 0px',
        maxWidth: '560px',
        color: 'var(--almost-black)'
    }),

    a: css({
        textDecoration: 'none',
        color: 'var(--primary-blue)',
        transition: 'var(--transition)'
    }),

    text: css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'left',
        border: '0px solid red',
    }),

    rightPanel: css({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center',

    }),

    graphic: css({
        height: '50%',
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', 
        alignItems: 'center',

    })
}

const Hero = () => {

    let date = new Date()
    let hrs = date.getHours()
    let greeting

    if (hrs < 12) {
        greeting = "morning";
      } else if (hrs < 18) {
        greeting = "afternoon";
      } else {
        greeting = "evening";
      }


  return (
    <Wrapper id='hero'>
        <Panel>
            <h1>Good {greeting}, my name is</h1>
            <h2>Lewis Potter.</h2>
            <h3>I'm a software developer.</h3>
            <p>
                I am a full stack web and mobile app developer with particular interests in 
                intuitive user interface design and cyber security. I currently build 
                perimeter detection applications for <a css={heroCss.a} href="https://www.detection-technologies.com/" target="_blank" rel="noreferrer"> Detection Technologies</a>.
            </p>
        </Panel>
        {/* <ChevronDown color='red' size={'50px'}/> */}
        {/* <div css={heroCss.rightPanel}>
            <div css={heroCss.graphic}><Animation /></div>
        </div> */}
        
    </Wrapper>
  )
}

const Wrapper = styled.div`
    min-height: 100vh;
    width: 100%;

    display: grid;
    grid-template-columns: 2fr 1fr;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 1.4em;
        padding-right: 1.4em;
      }
`

const Panel = styled.div`
    @keyframes one {
        0% {
            transform: translateX(-17%);
            opacity: 0.2;
        }
        100% {
            transform: translateX(0)
            opacity: 1;
        }
    }


    display: flex;
    flex-direction: column;
    justify-content: center; 
    align-items: left;
    border: 0px solid red;

    @media (max-width: 480px) and (min-height: 700px) {
        padding-bottom: 10vh;
      }

    h1 {
        margin: 0px 0px 25px 4px;
        color: var(--primary-blue);
        font-size: clamp(var(--fz-sm), 5vw, var(--fz-md));
        font-weight: 400;
        line-height: 1.1;

        @media (max-width: 480px) {
            margin: 0 0 0px 0px;
        };

        animation: 550ms ease 0s 1 one;
    }

    h2 {
        margin: 0;
        font-weight: 600;
        line-height: 1.1;
        font-size: clamp(40px, 8vw, 80px);
        color: var(--secondary-grey);
        animation: 650ms ease 0s 1 one;
    }

    h3 {
        margin-top: 10px;
        margin: 0;
        color: var(--secondary-blue);
        line-height: 0.9;
        font-size: clamp(40px, 8vw, 80px);
        animation: 800ms ease 0s 1 one;
    }

    p {
        margin: 20px 0px 0px;
        max-width: 560px;
        color: var(--almost-black);
        animation: 870ms ease 0s 1 one;
    }
`

export default Hero