/** @jsxImportSource @emotion/react */
import React from 'react'
import { css } from '@emotion/react'
import { ChevronRight } from 'react-feather'
import styled from 'styled-components'

const workCss = {
    work: css({
      minHeight:'100vh',
      width: '100%',

      display:'flex',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center'
    }),

    container: css({
      maxWidth: '900px',
    }),

    header: css({
      display: 'flex',
      justifyContent:'flex-start',
      alignItems: 'center',
      width: '100%',
      fontSize: 'clamp(26px, 5vw, var(--fz-heading))',
      fontWeight: '500',
      color: 'var(--secondary-blue)',
      margin: '10px 0px 40px'
    }),

    line: css({
      width: '365px',
      height: '1px',
      marginLeft: '20px',
      backgroundColor: 'var(--secondary-blue)'
    }),

    inner: css({
      display: 'flex',
      flexDirection: 'column',
      minHeight: '340px'
    }),

    tabList: css({
      position: 'relative',
      zIndex: '3',
      width: 'max-content',
      padding: '0px', 
      margin: '0px',
      listStyle: 'none'
    }),

    tabPanel: css({
      position: 'relative',
      width: '100%',
    }),

    positionTitle: css({
      marginBottom: '2px',
      fontSize: 'var(--fz-xxl)',
      fontWeight: '500',
      lineHeight: '1.3'
    }),

    company: css({
      color: 'var(--secondary-blue)'
    }),

    range: css({
      marginBottom: '25px',
      fontSize: 'var(--fz-xs)'
    }),

    tasks: css({
      paddingLeft: '0px',
      listStyle: 'none',
      fontSize: 'var(--fz-lg)'
    }),

    task: css({
      paddingLeft: '0px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      position: 'relative',
      marginBottom: '10px'
    })
} 

const Container = styled.div`
  max-width: 900px;


  @media (max-width: 768px) {
    width: 100%;
    padding-left: 2em;
    padding-right: 2em;
    border: 0px solid red;
  }

`

const Work = () => {
  return (
    <div css={workCss.work} id='work'>
      <Container>
        <div css={workCss.header}>Career<div css={workCss.line}></div></div>
        <div css={workCss.inner}>
        <div css={workCss.tabPanel}>
            <h3 css={workCss.positionTitle}><span>Lead Developer</span> <span css={workCss.company}>@ Detection Technologies</span></h3>
            <p css={workCss.range}>Mar 2023 - Present</p>
            <div>
              <ul css={workCss.tasks}>
                <li css={workCss.task}><ChevronRight /><span>Leading Development of Mobile Applications</span></li>
                <li css={workCss.task}><ChevronRight /><span></span>Refactoring Legacy Code</li>
                <li css={workCss.task}><ChevronRight /><span>Remote support for UK & Europe Teams</span></li>
              </ul>
            </div>
          </div>
          <div css={workCss.tabPanel}>
            <h3 css={workCss.positionTitle}><span>Full Stack Developer</span> <span css={workCss.company}>@ Detection Technologies</span></h3>
            <p css={workCss.range}>Jan 2022 - Mar 2023</p>
            <div>
              <ul css={workCss.tasks}>
                <li css={workCss.task}><ChevronRight /><span>Full Stack Development of access control applications</span></li>
                <li css={workCss.task}><ChevronRight /><span>UX/UI Design & Implementation</span></li>
                <li css={workCss.task}><ChevronRight /><span>Cloud Services Development</span></li>
              </ul>
            </div>
          </div>
          <div css={workCss.tabPanel}>
            <h3 css={workCss.positionTitle}><span>Student of Cyber Security</span> <span css={workCss.company}>@ De Montfort University</span></h3>
            <p css={workCss.range}>Sep 2018 - Jun 2021</p>
            <div>
              <ul css={workCss.tasks}>
                <li css={workCss.task}><ChevronRight /><span>Penetration testing & Secure Development</span></li>
                <li css={workCss.task}><ChevronRight /><span>Dissertation on consumer drone security</span></li>
                <li css={workCss.task}><ChevronRight /><span>Founded & led sessions for DMU MMA Society</span></li>
              </ul>
            </div>
          </div>
        </div>

      </Container>
    </div>
  )
}

export default Work