/** @jsxImportSource @emotion/react */

//REACT
import React from 'react'
import { css } from '@emotion/react'
import logo from '../../resources/logo.png'
import styled from 'styled-components'

const navbarCss = {
    bar: css({
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 11,
        height: 'var(--nav-height)',
        width: '100%',

        display: 'flex',
        justifyContent: 'space-between',
        alignItems:'center',

        paddingLeft: '50px',
        paddingRight: '50px',
    
        backdropFilter: 'blur(5px)',
        transition: 'var(--transition)'
    }),

    linkGroup: css({
        display: 'flex',
        alignItems: 'center',
    }),

    ol: css({
        padding: 0,
        margin: 0,
        listStyle: 'none',

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap:'2em',
        color: 'var(--secondary-blue)',
        cursor: 'pointer'
    }),

    button: css({
        width: '6em',
        height:'3em',
        backgroundColor: 'var(--secondary-blue)',
        border: '1.7px solid var(--secondary-blue)',
        color: 'white',
        transition: '200ms ease-in-out',
        cursor: 'pointer',
        borderRadius:'6px',
        ':hover': {
        }
    }),

    logo: css({
        fontSize: '2em',
        fontWeight: 500,
        color: 'var(--secondary-blue)'
    })
}

const NavBar = () => {

    return (
        <Nav>
            <img alt='logo' style={{cursor: 'pointer'}} src={logo} width='60px' height='60px' onClick={() => document.querySelector('#hero')?.scrollIntoView({ behavior: 'smooth', block: 'center'})}></img>
            <LinkGroup>
                <ol css={navbarCss.ol}>
                    <li onClick={() => document.querySelector('#about')?.scrollIntoView({ behavior: 'smooth', block: 'center'})}>
                        About
                    </li>
                    <li onClick={() => document.querySelector('#work')?.scrollIntoView({ behavior: 'smooth', block: 'center'})}>
                        Work
                    </li>
                    <li onClick={() => document.querySelector('#projects')?.scrollIntoView({ behavior: 'smooth', block: 'center'})}>
                        Projects
                    </li>
                    <li onClick={() => document.querySelector('#contact')?.scrollIntoView({ behavior: 'smooth', block: 'center'})}>
                        Contact
                    </li>
                    <li>
                        <a className="resume-button" href={process.env.PUBLIC_URL + '/CV.pdf'} download target="_blank" rel="noopener noreferrer">
                            <button css={navbarCss.button}>
                                CV
                            </button>
                        </a>
                    </li>
                </ol>
                
            </LinkGroup>
        </Nav>
    ) 
}

const Nav = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    height: var(--nav-height);
    width: 100%;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 50px;
    padding-right: 50px;

    backdrop-filter: blur(5px);
    transition: var(--transition);

    @media (max-width: 1080px) {
        padding: 0 40px;
      }
      @media (max-width: 768px) {
        padding: 0 25px;
      }
`

const LinkGroup = styled.div`
    display: flex;
    align-items: center;   

    @media (max-width: 768px) {
        display: none;
      }
`

export default NavBar